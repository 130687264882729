import React from 'react';

const PodcastList = () => {
    const podcasts = [
        { id: 1, title: "エメリア電波塔 Vol.1", src: "/podcasts/第1回エメリア電波塔.mp3", description: "・自己紹介\n・なぜVTuberではなく、Webラジオを始めたのか" },
        { id: 2, title: "エメリア電波塔 Vol.2", src: "/podcasts/第2回エメリア電波塔.mp3", description: "・VRChatの話\n・新居を決めた話\n・高い声が出せない話" },
        { id: 3, title: "エメリア電波塔 Vol.3", src: "/podcasts/第3回エメリア電波塔.mp3", description: "・Amazonの欲しいものリストの話\n・クリスマスの話\n・最近ココアがおいしい話" },
        { id: 4, title: "エメリア電波塔 Vol.4", src: "/podcasts/第4回エメリア電波塔.mp3", description: "・2018年という年を振り返って \n・私がこのラジオ放送を始めようと思ったきっかけになった放送の話" },
        { id: 5, title: "エメリア電波塔 Vol.5", src: "/podcasts/第5回エメリア電波塔.mp3", description: "・初詣の話 \n・お水の話\n・Oneshotの話" },
        { id: 6, title: "エメリア電波塔 Vol.6", src: "/podcasts/第6回エメリア電波塔.mp3", description: "・引っ越しした話\n・紅茶の話\n・FPSの話" },
        { id: 7, title: "エメリア電波塔 Vol.7", src: "/podcasts/第7回エメリア電波塔.mp3", description: "・髪を切りに行った話\n・記事を書く、ということについての話" },
        { id: 8, title: "エメリア電波塔 Vol.8", src: "/podcasts/第8回エメリア電波塔.mp3", description: "・ダーツの話\n・Githubの話" },
        { id: 9, title: "エメリア電波塔 Vol.9", src: "/podcasts/第9回エメリア電波塔.mp3", description: "・技術書展に行ってきた話\n・VOCALOIDの話" },
        { id: 10, title: "エメリア電波塔 Vol.10", src: "/podcasts/第10回エメリア電波塔.mp3", description: "・研究室にコーヒー器具を持って行った話\n・Nier:Automataの話\n・新しいダーツを買った話" },
        { id: 11, title: "エメリア電波塔 Vol.11", src: "/podcasts/第11回エメリア電波塔.mp3", description: "・フルワイヤレスイヤホンを買った話\n・Spotifyの話\n・コメントフォームを作った話" },
        { id: 12, title: "エメリア電波塔 Vol.12", src: "/podcasts/第12回エメリア電波塔.mp3", description: "・このラジオを話題に上げていただいた話\n・京都のとある喫茶店の話" },
        { id: 13, title: "エメリア電波塔 Vol.13", src: "/podcasts/第13回エメリア電波塔.mp3", description: "・キャラクターの名前の話\n・光のお父さんを見てきた話\n・後輩君とあった話" },
        { id: 14, title: "エメリア電波塔 Vol.14", src: "/podcasts/第14回エメリア電波塔.mp3", description: "・ネットで知り合った地元民の話\n・プロコンを毎日の習慣にした話\n・同人誌に出させていただいた話" },
        { id: 15, title: "エメリア電波塔 Vol.15", src: "/podcasts/第15回エメリア電波塔.mp3", description: "・ドラクエWalkを始めた話\n・キーボードの話" },
        { id: 16, title: "エメリア電波塔 Vol.16", src: "/podcasts/第16回エメリア電波塔.mp3", description: "・エオコンに行ってきた話\n・ドラクエウォークを続けている話" },
        { id: 17, title: "エメリア電波塔 Vol.17", src: "/podcasts/第17回エメリア電波塔.mp3", description: "・DJコントローラーを買った話\n・チョコレートムースを作った話" },
        { id: 18, title: "エメリア電波塔 Vol.18", src: "/podcasts/第18回エメリア電波塔.mp3", description: "・Switch/ポケモンを買った話\n・コンビニのおでんが美味しい話" },
        { id: 19, title: "エメリア電波塔 Vol.19", src: "/podcasts/第19回エメリア電波塔.mp3", description: "・初めて飛行機に乗った話\n・RFA（リングフィットアドベンチャー）の話\n・Vtuberの話" },
        { id: 20, title: "エメリア電波塔 Vol.20", src: "/podcasts/第20回エメリア電波塔.mp3", description: "・エメリア電波塔の話\n・ご飯を電子レンジで作った話" },
        { id: 21, title: "エメリア電波塔 Vol.21", src: "/podcasts/第21回エメリア電波塔.mp3", description: "・豆乳の話\n・会話が上手な人の話" },
        { id: 22, title: "エメリア電波塔 Vol.22", src: "/podcasts/第22回エメリア電波塔.mp3", description: "・コーヒーの蓋の話\n・しゃけ缶の話\n・3Dモデリングを始めた話" },
        { id: 23, title: "エメリア電波塔 Vol.23", src: "/podcasts/第23回エメリア電波塔.mp3", description: "・Webカメラを買いに行った話\n・osu!の話\n・モデリングの話" },
        { id: 24, title: "エメリア電波塔 Vol.24", src: "/podcasts/第24回エメリア電波塔.mp3", description: "・ラジオのジングルの話\n・最近の食生活の話" },
        { id: 25, title: "エメリア電波塔 Vol.25", src: "/podcasts/第25回エメリア電波塔.mp3", description: "・ボイトレに行ってみたい話\n・行ったことのないお店を開拓したい話" },
        { id: 26, title: "エメリア電波塔 Vol.26", src: "/podcasts/第26回エメリア電波塔.mp3", description: "・ノイズキャンセリングの話\n・口内炎の話\n・ボイトレの体験レッスンに行ってきた話" },
        { id: 27, title: "エメリア電波塔 Vol.27", src: "/podcasts/第27回エメリア電波塔.mp3", description: "・好き嫌いの定義の話\n・服を買った話\n・タイトルがない曲の話" },
        { id: 28, title: "エメリア電波塔 Vol.28", src: "/podcasts/第28回エメリア電波塔.mp3", description: "・文房具カフェに行った話\n・新しいメガネを買った話\n・音楽と記憶の話" },
        { id: 29, title: "エメリア電波塔 Vol.29", src: "/podcasts/第29回エメリア電波塔.mp3", description: "・荷造りの話\n・紅葉の話\n・職業病の話" },
        { id: 30, title: "エメリア電波塔 Vol.30", src: "/podcasts/第30回エメリア電波塔.mp3", description: "・出前オンリーのお店の話\n ・一人旅をする予定の話\n・お絵かきもっと上手になりたい話" },
        { id: 31, title: "エメリア電波塔 Vol.31", src: "/podcasts/第31回エメリア電波塔.mp3", description: "・サイトを移転した話\n ・一人旅をした話\n・社会人になってしまった話" },
        { id: 32, title: "エメリア電波塔 Vol.32", src: "/podcasts/第32回エメリア電波塔.mp3", description: "・リモートワークの話\n ・マットレスを買いたい話\n・アクリルアートプレートが届いた話" },
        { id: 33, title: "エメリア電波塔 Vol.33", src: "/podcasts/第33回エメリア電波塔.mp3", description: "・転職をした話\n ・ギターを買った話\n・VRChatを再開した話" },
        // 以下に他のエピソードも追加
    ];

    return (
        <div className="podcast-list">
            <h2>Podcast一覧</h2>
            {podcasts.slice().reverse().map((podcast) => (
                <div key={podcast.id} className="podcast-item">
                    <h3>{podcast.title}</h3>
                    <h4>{podcast.description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}</h4>
                    <audio controls>
                        <source src={podcast.src} type="audio/mpeg" />
                        お使いのブラウザはaudio要素をサポートしていません。
                    </audio>
                </div>
            ))}
        </div>
    );
};

export default PodcastList;
